import { Injectable } from '@angular/core';
import { getNewComponentId, Logger } from 'ngx-myia-core';
import { BackendService, ITokenData, ITokenService } from 'ngx-myia-http';
import { ReduxStore, setReduxStorage } from 'ngx-myia-redux';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap, share, tap, delay, map } from 'rxjs/operators';

import { authCompletedAction, authRefreshCompletedAction, authRefreshFailedAction, authRefreshStartedAction } from '../redux/authActions';
import { authReducerKey } from '../redux/authReducers';

@Injectable({providedIn: 'root'})
export class TokenService implements ITokenService {
    private _currentRefreshTokenRequest: Observable<ITokenData>;

    constructor(private _backendService: BackendService, private _store: ReduxStore, private _logger: Logger) {
    }

    /**
     * Make API call to token service
     * @param {string} email - user email
     * @param {string} password
     * @param {string} rememberMe flag
     * @return {Observable} - http request
     */
    load(clientId: string, accessToken: string, refreshToken: string, rememberMe: boolean) {
        return setReduxStorage(rememberMe ? 'local' : 'session')
            .pipe(
                tap(() => {
                    this._store.dispatch(authCompletedAction(clientId, accessToken, refreshToken));
                })
            );
    }

    refreshToken(): Observable<ITokenData> {
        // check if 'refresh token' request already running
        if (this._currentRefreshTokenRequest) {
            this._logger.log('Reused existing request for \'refresh token\'.');
            return this._currentRefreshTokenRequest;
        }
        const authState = this._store.getState(authReducerKey);
        if (authState) {
            const clientId = authState.get('clientId', null);
            const refreshToken = authState.get('refreshToken', null);
            if (refreshToken) {
                const data = {
                    refresh_token: refreshToken,
                    grant_type: 'refresh_token',
                    client_id: clientId
                };

                this._store.dispatch(authRefreshStartedAction(refreshToken));
                this._currentRefreshTokenRequest = this._backendService.postJSON<ITokenData>('/token', JSON.stringify(data), {noAuth: true})
                    .pipe(
                        mergeMap((data: ITokenData) => {
                            this._currentRefreshTokenRequest = null;
                            this._store.dispatch(authRefreshCompletedAction(data.access_token, data.refresh_token || refreshToken));
                            return of(null);
                        }),
                        catchError(err => {
                            this._currentRefreshTokenRequest = null;
                            this._store.dispatch(authRefreshFailedAction());
                            this._logger.warn('Could not refresh token.');
                            return throwError(err);
                        }),
                        share()
                    );
                return this._currentRefreshTokenRequest;
            }
        }
        this._logger.warn('Refresh token not available.');
        this._store.dispatch(authRefreshFailedAction());
        return throwError('Refresh token not available.');
    }

    getSessionToken(): Observable<string> {
        return this._backendService.get('/sessionToken').pipe(
            map(result => result.token)
        );
    }

    getExternalToken(clientId: string, code: string): Observable<ITokenData> {
        const data = {
            clientId,
            code
        };
        return this._backendService.postJSON<ITokenData>('/externaltoken', JSON.stringify(data), {noAuth: true});
    }

    getQrCodeForAccess(clientId: string): Observable<string> {
        return of(`myia://webLogin?token=${clientId}`)
    }

    _test_signalR_login(clientId: string, deviceId: string, webLoginKey: string): Observable<boolean> {
        const webLoginData = {
            deviceId,
            clientId
        };
        return this._backendService.postJSON<{sent: boolean}>(`/webLogin?auth_key=${webLoginKey}`, JSON.stringify(webLoginData))
            .pipe(
                map(result => result.sent),
                catchError(err => {
                        this._logger.error('Test signalR login failed: ' + err);
                        return throwError(err);
                    }
                )
            );
    }

    _test_invalidate_token(invalidateToken: boolean, invalidateRefreshToken: boolean) {
        const authState = this._store.getState(authReducerKey);
        if (authState) {
            const clientId = authState.get('clientId');
            const token = authState.get('token') + (invalidateToken ? 'X' : '');
            const refreshToken = authState.get('refreshToken') + (invalidateRefreshToken ? 'X' : '');
            this._store.dispatch(authCompletedAction(clientId, token, refreshToken));
        }
    }
}

