export class AutoSizeHelper {

    private static _autoSize: any;

    static update(el: HTMLElement) {
        AutoSizeHelper.getAutoSizeLib(() => {
            AutoSizeHelper._autoSize.update(el);
        });
    }

    static destroy(el: HTMLElement) {
        AutoSizeHelper.getAutoSizeLib(() => {
            AutoSizeHelper._autoSize.destroy(el);
        });
    }

    static applyToElement(el: HTMLElement) {
        AutoSizeHelper.getAutoSizeLib(() => {
            AutoSizeHelper._autoSize(el);
        });
    }

    private static getAutoSizeLib(callBack: Function) {
        if (AutoSizeHelper._autoSize) {
            callBack(AutoSizeHelper._autoSize);
        }
        else {
            (<any>require).ensure([], () => {
                AutoSizeHelper._autoSize = require('autosize');
                callBack();
            });
        }
    }


}
