export const AUTH_COMPLETED = 'AUTH_COMPLETED';

export const AUTH_REFRESH_STARTED = 'AUTH_REFRESH_STARTED';
export const AUTH_REFRESH_COMPLETED = 'AUTH_REFRESH_COMPLETED';
export const AUTH_REFRESH_FAILED = 'AUTH_REFRESH_FAILED';

export const AUTH_LOG_OUT = 'AUTH_LOG_OUT';

export function authCompletedAction(clientId: string, token: string, refreshToken: string) {
    return {
        type: AUTH_COMPLETED,
        clientId: clientId,
        token: token,
        refreshToken: refreshToken
    };
}

export function authRefreshStartedAction(refreshToken: string) {
    return {
        type: AUTH_REFRESH_STARTED,
        refreshToken: refreshToken
    };
}

export function authRefreshCompletedAction(token: string, refreshToken: string) {
    return {
        type: AUTH_REFRESH_COMPLETED,
        token: token,
        refreshToken: refreshToken
    };
}

export function authRefreshFailedAction() {
    return {
        type: AUTH_REFRESH_FAILED
    };
}

export function authLogoutAction() {
    return {
        type: AUTH_LOG_OUT
    };
}
