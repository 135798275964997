export const EventRoutePath = 'event';
export const DashboardRoutePath = 'dashboard';
export const LoginRoutePath = 'login';
export const LogoutRoutePath = 'logout';
export const AccessDeniedPath = 'accessDenied';
export const Customers = {
  Pref2019: {
      LoginRoutePath: 'pref'
  }
};
