
              <div class="textFieldGroup" [ngClass]="{required: isRequired, modified: isModified, withLabel: label, hasValue: value | hasValue, init: isInitialized}">
                <input #inputEl [id]="fieldName" [attr.maxlength]="maxLength" [type]="isPassword ? 'password' : 'text'" (blur)="onBlur()" (keydown)="onKeyDown($event)" (keyup)="onKeyUp($event)" (focus)="onFocus()" [formControl]="control" [ngClass]="{hasValue: value | hasValue, password: isPassword}" [readonly]="readonly" [tabIndex]="tabIndex" [attr.autocomplete]="disableBrowserAutoComplete || isPassword || completerService ? 'off' : 'on'" [attr.autocorrect]="disableBrowserAutoComplete || isPassword || completerService ? 'off' : 'on'" [attr.autocapitalize]="disableBrowserAutoComplete || isPassword || completerService ? 'off' : 'on'" [tooltip]="message" [tooltipPlacement]="messagePlacement" [tooltipEnable]="!value" [tooltipDelay]="0"/>
                <div *ngIf="!readonly" class="reqMark line"></div>
                <div *ngIf="label" class="bar"></div>
                <label *ngIf="label">{{label}}</label>
                <div class="autoCompleteIcon" *ngIf="showListIcon && !isDisabled && !value" (click)="showAutoCompleteList($event)"><svg-icon name="drop-down-list"></svg-icon></div>
                <div *ngIf="completerOpened" class="completerDropDownBlock" [ngClass]="{withValue: value}">
                    <div *ngIf="isSearching && displaySearching && !autoCompleteItems?.length" class="completer-searching">{{textSearching}}</div>
                    <div *ngIf="!isSearching && displayNoResults && (!autoCompleteItems?.length)" class="completer-no-results">{{textNoResults}}</div>
                    <progress-indicator-bar *ngIf="isSearching && displaySearching && autoCompleteItems?.length" indicatorClass="completer-searching-loader blockLoader fromLeft"></progress-indicator-bar>
                    <div class="completer-row-wrapper" *ngFor="let item of autoCompleteItems; let rowIndex=index; trackBy: trackCompleterItem">
                        <div class="completer-row" [ngClass]="{selected: highlightedItem === item}" (click)="selectCompleterItem(item)" (mouseEnter)="highlightItem(item)">
                            <div *ngIf="item.image || item.image === ''" class="completer-image-holder">
                                <img *ngIf="item.image != ''" src="{{item.image}}" class="completer-image" />
                                <div *ngIf="item.image === ''" class="completer-image-default"></div>
                            </div>
                            <div class="completer-item-text" [ngClass]="{'completer-item-text-image': item.image || item.image === '' }">
                                <div class="title">{{item.title}}</div>
                                <div class="description" *ngIf="item.description">{{item.description}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <control-messages *ngIf="!readonly" [messages]="control|validationErrors"></control-messages>
                <button *ngIf="isPassword && value" class="togglePasswordBtn" type="button" (mousedown)="inputEl.type='text'" (mouseup)="inputEl.type='password'" [disabled]="isDisabled" tabindex="-1">
                    <svg-icon name="eye"></svg-icon>
                </button>
              </div>
              