
                <div dropdown (click)="$event.preventDefault()" [(isOpen)]="isOpen" [ngClass]="{modified: isModified, readOnly: readonly, invalid: control?.invalid, dirty: control?.dirty}">
                  <a href [id]="id" dropdownToggle [disabled]="disabled" [ngClass]="{empty: null === value && !nullValueTitle}">
                    <svg-icon *ngIf="iconPath && itemByValue(value)" name="{{itemByValue(value)[iconPath]}}"></svg-icon>
                    <span [innerHTML]="(itemByValue(value) ? itemByValue(value)[itemTitlePath] : (nullValueTitle || '&nbsp;'))|trans|sanitizeHtml"></span>
                  </a>
                  <input #inputEl type="text" autocomplete="off" tabindex="-1"
                       (keydown)="inputEvent($event)"
                       (keyup)="inputEvent($event, true)"
                       [disabled]="disabled"
                       class="searchBox"
                       *ngIf="editable"
                       placeholder="{{label || ''}}">
                  <label *ngIf="label">{{label}}</label>
                  <ul *ngIf="!readonly" dropdownMenu class="dropdown-menu" [attr.aria-labelledby]="id" #itemsContainer>
                      <li *ngIf="nullValueTitle" (click)="itemClicked($event, null)">
                        <a class="dropdown-item" href="#" [ngClass]="{selected: null === value, active: null === activeItem}"><span>{{nullValueTitle}}</span></a>
                      </li>
                      <li *ngFor="let choice of items; trackBy: trackItem" (click)="itemClicked($event, choice)">
                        <a class="dropdown-item" href="#" [ngClass]="{selected: choice === itemByValue(value), active: choice === activeItem}"><svg-icon *ngIf="iconPath" name="{{choice[iconPath]}}"></svg-icon><span>{{choice[itemTitlePath]|trans}}</span></a>
                      </li>
                  </ul>
                </div>
               