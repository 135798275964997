
        <div class="topBarRoot" [ngClass]="{menu: showNavMenu}">
            <div class="content" [ngClass]="{withMenu: showButtonsDots}">
                <ng-content select="[content]"></ng-content>
            </div>
            <div class="buttonsWrapper" #buttonsWrapper>
                <div class="buttons" [ngClass]="{collapsed: showButtonsDots, menu: showNavMenu}">
                    <span *ngIf="isDebugModeEnabled" class="debugBlock">
                        <dev-tools *ngIf="showDevToolsMenu" (close)="switchDevTools()"></dev-tools>
                        <button class="topBarButton withIcon debugTools" (click)="switchDevTools()" tooltip="Developer tools (Debug only)" tooltipPlacement="bottom" tooltipClass="noWrap" tooltipOffset="0,10" tooltipContainer=".topBarRoot">
                            <svg-icon name="bug"></svg-icon>
                            <label>Debugging tools</label></button>
                    </span>
                    <ng-content select="[buttons]"></ng-content>
                </div>
            </div>
            <button type="button" class="buttonsMenu" (click)="toggleMenu()" [ngClass]="{collapsed: !showButtonsDots}">
                <svg-icon name="dots"></svg-icon>
            </button>
        </div>
        <div class="notificationsContainer"></div>
    