import { AfterViewChecked, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Config, EmitterService, LAYOUT_RESIZED } from 'ngx-myia-core';
import { debounceTime } from 'rxjs/operators';
import { fromEvent, Subscription } from 'rxjs';

@Component({
    selector: 'top-bar',
    styleUrls: ['./topBar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <div class="topBarRoot" [ngClass]="{menu: showNavMenu}">
            <div class="content" [ngClass]="{withMenu: showButtonsDots}">
                <ng-content select="[content]"></ng-content>
            </div>
            <div class="buttonsWrapper" #buttonsWrapper>
                <div class="buttons" [ngClass]="{collapsed: showButtonsDots, menu: showNavMenu}">
                    <span *ngIf="isDebugModeEnabled" class="debugBlock">
                        <dev-tools *ngIf="showDevToolsMenu" (close)="switchDevTools()"></dev-tools>
                        <button class="topBarButton withIcon debugTools" (click)="switchDevTools()" tooltip="Developer tools (Debug only)" tooltipPlacement="bottom" tooltipClass="noWrap" tooltipOffset="0,10" tooltipContainer=".topBarRoot">
                            <svg-icon name="bug"></svg-icon>
                            <label>Debugging tools</label></button>
                    </span>
                    <ng-content select="[buttons]"></ng-content>
                </div>
            </div>
            <button type="button" class="buttonsMenu" (click)="toggleMenu()" [ngClass]="{collapsed: !showButtonsDots}">
                <svg-icon name="dots"></svg-icon>
            </button>
        </div>
        <div class="notificationsContainer"></div>
    `,
})
export class TopBarComponent implements AfterViewInit, AfterViewChecked, OnInit, OnDestroy {

    @HostBinding('class.topBar') hostClass = true;

    @ViewChild('buttonsWrapper', {static: true}) buttonsWrapper: ElementRef;

    showNavMenu: boolean;
    showDevToolsMenu: boolean;
    isDebugModeEnabled: boolean;

    showButtonsDots: boolean = false;

    private _documentClickFunc: Function;
    private _windowResizeSub: Subscription;
    private _layoutResizedSub: any;

    private onResizeBind: EventListener = this.onResize.bind(this);


    constructor(private _changeDetectorRef: ChangeDetectorRef, private _renderer: Renderer2) {
        this.isDebugModeEnabled = Config.get<boolean>('showDevTools', false);
    }

    ngAfterViewInit(): void {
        this.checkButtonsCollapseState();
    }

    ngAfterViewChecked(): void {
        this.checkButtonsCollapseState();
    }


    switchDevTools() {
        this.showDevToolsMenu = !this.showDevToolsMenu;
        this._changeDetectorRef.markForCheck();
    }

    toggleMenu() {
        this.showNavMenu = !this.showNavMenu;
        if (this.showNavMenu) {
            this.subscribeGlobalEvents();
        } else {
            this.unsubscribeGlobalEvents();
        }
    }

    ngOnInit() {
        this._windowResizeSub = fromEvent(window, 'resize').pipe(
            debounceTime(50)
        ).subscribe(this.onResizeBind);
        this._layoutResizedSub = EmitterService.getEvent(LAYOUT_RESIZED).subscribe(this.onResizeBind);

    }

    ngOnDestroy() {
        this.unsubscribeGlobalEvents();
        if (this._windowResizeSub) {
            this._windowResizeSub.unsubscribe();
            this._windowResizeSub = null;
        }
        if (this._layoutResizedSub) {
            this._layoutResizedSub.unsubscribe();
            this._layoutResizedSub = null;
        }
    }


    private subscribeGlobalEvents() {
        this.unsubscribeGlobalEvents();

        // handle click events on document to hide event list menu
        this._documentClickFunc = this._renderer.listen('document', 'click', (event) => {
            let current = event.target;
            while (current.tagName !== 'BODY' && current.tagName !== 'HTML') {
                if (current.classList.contains('buttonsMenu')) {
                    return;
                }
                current = current.parentNode;
                if (!current) {
                    // dom is not child of body (probably already removed from DOM)
                    return;
                }
            }
            this.unsubscribeGlobalEvents();
            this.showNavMenu = false;
            this._changeDetectorRef.markForCheck();
        });
    }

    private unsubscribeGlobalEvents() {
        if (this._documentClickFunc) {
            this._documentClickFunc();
            this._documentClickFunc = null;
        }
    }

    private onResize() {
        if (this.showNavMenu) {
            this.unsubscribeGlobalEvents();
            this.showNavMenu = false;
            this._changeDetectorRef.markForCheck();
        }
        this.checkButtonsCollapseState();
    }

    private checkButtonsCollapseState() {
        const showButtonsDots = this.buttonsWrapper && this.buttonsWrapper.nativeElement && this.buttonsWrapper.nativeElement.firstChild && this.buttonsWrapper.nativeElement.firstChild.getBoundingClientRect && this.buttonsWrapper.nativeElement.getBoundingClientRect().width < this.buttonsWrapper.nativeElement.firstChild.getBoundingClientRect().width;
        if (this.showButtonsDots !== showButtonsDots) {
            this.showButtonsDots = showButtonsDots;
            this._changeDetectorRef.markForCheck();
            this._changeDetectorRef.detectChanges();
        }
    }

}


